.container {
    display: flex;
    flex-direction:column;
    justify-content: space-around;
    height: 100vh;
    text-align: center;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .flex-home{
    width: 100vw;
    display:flex;
    justify-content: space-evenly;
    margin-top: 200px;
    flex-wrap: wrap;
  }

  .header {
    margin-bottom: 20px;
  }
  
  .logo {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .title h1 {
    
    font-size: 2.5rem;
    color: #333;
  }
  
  .title p {
    
    font-size: 1rem;
    color: #777;
  }
  
  .menu {
    width: 28vw;
    background-color: #fdf8f6;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
  }
  
  .menu-item, .menu-item a {
    border-bottom: 1px solid #e3cebf;
    font-size: 1.4rem;
    color: #333;
    cursor: pointer;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: center; 
    padding: 20px 0;
  }
 
  .menu-item:last-child {
    border-bottom: none;
  }
  
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 100px;
  }
  .footer img{
    margin-right: 36px;
  }
  
  .social-icon {
    width: 40px;
    margin: 0 10px;
    cursor: pointer;
  }

  /* Estilos específicos para telas com largura máxima de 320px */
@media (max-width: 1024px) {
  .menu {
    width: 80vw;
  }
  .flex-home{
    margin-top:0;
    width: 100%;
  }
}
  