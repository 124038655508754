.container {
    display: flex;
    flex-direction:column;
    justify-content: space-around;
    height: 100vh;
    text-align: center;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .txt-contato img{
    max-width: 500px;
  }