.container {
    display: flex;
    flex-direction:column;
    justify-content: space-around;
    height: 100vh;
    text-align: center;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .p-r{
    position: relative;
  }
  .p-r img{
    position: absolute;
    left: 5px;
    width: 28px;
    top:4px;
  }
  .bach .alg-center{
    text-align: center;
  }
  .bach .alg-left{
    text-align: left;
  }
  .bach .ft-w-b{
    font-weight: 400;
  }
  .bach .ft-w-bb{
    font-weight: 500;
  }
  .bach .title{
   text-align: center; 
   border-bottom: 1px solid #e3cebf;
    font-size: 1.4rem;
    color: #333;
    cursor: pointer;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  .bach .menu-item{
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bach .menu-item img{ 
    max-height: 40px;
    margin-right:12px;
  }
  .bach .description-box{
    margin-left: 10px;
    padding-right:55px;
  }

  @media (max-width: 480px) {
      .bach .menu-item {
        flex-direction: column;
      }
      .footer{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-right: 0;
      }
      .menu-item img{
        margin-top:20px;
      }
      .footer img {
        margin:0;
        padding-left:10px;
        margin-right:10px;
      }
      .menu{
        text-align: center;
      }
      .header img{
        width: 90%;
      }
  }